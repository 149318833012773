import React from "react"
import { Container, Col, Row } from "reactstrap"
import "./HowCanWeAssistYou.scss"
import ico1 from "../../assets/ico1.png"
import ico2 from "../../assets/ico2.png"
import ico3 from "../../assets/ico3.png"
import ico4 from "../../assets/ico4.png"
import Button from "../../components/UI/Button/Button"
import Link from '../../components/Link/Link'
import { withTranslation } from "react-i18next"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const HowCanWeAssistYou = ({ t }) => {
  return (
    <section className="how-can-we-assist-you" id="how-can-we-assist-you">
      <Container>
        <h2>{t('HOW_CAN_WE_ASSIST_TITLE')}<br /> {t('HOW_CAN_WE_ASSIST_TITLE_SECOND')}</h2>
        <div className="info-tiles normal-tiles">
          <Row>
            <Col className="element" xs="5">
              <div className="title-container">
                <img src={ico1} className="ico"  alt='' title='salona-consulting'/>
                <p className="tile-title">
                  {t("HOW_CAN_WE_ASSIST_GENEALOGICAL_RESEARCH")}
                </p>
              </div>
              <p className="text">{t("HOW_CAN_WE_ASSIST_GENEALOGICAL_SUB")}</p>
            </Col>
            <Col className="element" xs="5">
              <div className="title-container">
                <img src={ico2} className="ico" alt='' />
                <p className="tile-title">
                  {t("HOW_CAN_WE_ASSIST_DOCUMENTATION")}
                </p>
              </div>
              <p className="text">{t("HOW_CAN_WE_ASSIST_DOCUMENTATION_SUB")}</p>
            </Col>
            <Col className="element" xs="5">
              <div className="title-container">
                <img src={ico3} className="ico" alt=''/>
                <p className="tile-title">{t("HOW_CAN_WE_ASSIST_CERTIFIED")}</p>
              </div>
              <p className="text">{t("HOW_CAN_WE_ASSIST_CERTIFIED_SUB")}</p>
            </Col>
            <Col className="element" xs="5">
              <div className="title-container">
                <img src={ico4} className="ico" alt=''/>
                <p className="tile-title">{t("HOW_CAN_WE_ASSIST_PASSPORT")}</p>
              </div>
              <p className="text">{t("HOW_CAN_WE_ASSIST_PASSPORT_SUB")}</p>
            </Col>
          </Row>
        </div>
        <div className="btn-container">
          <Link to='/our-services'>
            <Button className="btn-2 large borderless" title={t("OUR_SERVICES")} />
          </Link>
        </div>

        {/* carousel for mobile */}
        
        <div className="info-tiles mobile-carousel">
          <Carousel
            className="carousel"
            responsive={responsive}
            slidesToSlide={1}
          >
            <div className="element">
              <div className="title-container">
                <img src={ico1} className="ico" alt='salona-constuling assist'/>
                <p className="tile-title">
                  {t("HOW_CAN_WE_ASSIST_GENEALOGICAL_RESEARCH")}
                </p>
              </div>
              <p className="text">{t("HOW_CAN_WE_ASSIST_GENEALOGICAL_SUB")}</p>
            </div>
            <div className="element">
              <div className="title-container">
                <img src={ico2} className="ico" alt='salona-constuling assist'/>
                <p className="tile-title">
                  {t("HOW_CAN_WE_ASSIST_DOCUMENTATION")}
                </p>
              </div>
              <p className="text">{t("HOW_CAN_WE_ASSIST_DOCUMENTATION_SUB")}</p>
            </div>
            <div className="element">
              <div className="title-container">
                <img src={ico3} className="ico" alt='salona-constuling assist'/>
                <p className="tile-title">{t("HOW_CAN_WE_ASSIST_CERTIFIED")}</p>
              </div>
              <p className="text">{t("HOW_CAN_WE_ASSIST_CERTIFIED_SUB")}</p>
            </div>
            <div className="element">
              <div className="title-container">
                <img src={ico4} className="ico" alt='salona-constuling assist'/>
                <p className="tile-title">{t("HOW_CAN_WE_ASSIST_PASSPORT")}</p>
              </div>
              <p className="text">{t("HOW_CAN_WE_ASSIST_PASSPORT_SUB")}</p>
            </div>
          </Carousel>
        </div>
      </Container>
    </section>
  )
}

export default withTranslation()(HowCanWeAssistYou)
