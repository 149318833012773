import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import {
  Container,
  Col,
  Row,
} from 'reactstrap'
import './OurCompany.scss'
import Button from '../../components/UI/Button/Button'
import Carousel from 'react-multi-carousel'
import  Link from '../../components/Link/Link'

const OurCompany = ({ t }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  }
  return (
    <section className='our-company' id='our-company'>
      <Container>
        <Row className='company'>
          <Col className='boxes'>
            <Row>
              <Col xs="6">
                <div className='blue-box type-1'>
                  <h5>500+</h5>
                  <p>{t('OUR_COMPANY_CERTIFICATES')}</p>
                </div>
                <div className='red-box type-2'>
                  <h5>300+</h5>
                  <p>{t('OUR_COMPANY_APPLICATIONS')}</p>
                </div>
              </Col>
              <Col xs="6">
                <div className='red-box type-3'>
                  <p>{t('OUR_COMPANY_CLIENTS')}</p>
                </div>
                <div className='blue-box type-4'>
                  <p>{t('OUR_COMPANY_HEADQUARTERS')}</p>
                </div>
              </Col>
            </Row>
          </Col>
          <Carousel
            className='carousel-values'
            responsive={responsive}
            slidesToSlide={1}
          >
            <Col className='item'>
              <div className='blue-box'>
                <h5>500+</h5>
                <p>{t('OUR_COMPANY_CERTIFICATES')}</p>
              </div>
            </Col>
            <Col className='item'>
              <div className='red-box'>
                <h5>300+</h5>
                <p>{t('OUR_COMPANY_APPLICATIONS')}</p>
              </div>
            </Col>
            <Col className='item'>
              <div className='red-box'>
                <p>{t('OUR_COMPANY_CLIENTS')}</p>
              </div>
            </Col>
            <Col className='item'>
              <div className='blue-box'>
                <p>{t('OUR_COMPANY_HEADQUARTERS')}</p>
              </div>
            </Col>
          </Carousel>
          <Col xs="12" md='12' lg='6' className='our-company-col'>
            <h2>{t('OUR_COMPANY')}</h2>
            <div className='description'>
              <p>{t('OUR_COMPANY_MISSION')}</p>
            </div>
            <div className='contact-box'>
              <p>{t('OUR_COMPANY_INTERESTED')}</p>
              <Link to='/contact'>
                <Button className='btn-contact' title={t("CONTACT_US")} />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

OurCompany.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(OurCompany)
