import React from "react"
import { Container, Col, Row } from "reactstrap"
import "./WhatAreTheBenefits.scss"

import pic1 from "../../assets/1-citizen.jpg"
import pic2 from "../../assets/2-citizen.jpg"
import pic3 from "../../assets/3-citizen.jpg"
import pic4 from "../../assets/4-citizen.jpg"
import { withTranslation } from "react-i18next"
import Carousel from 'react-multi-carousel'
import Button from '../../components/UI/Button/Button'
import Link from '../../components/Link/Link'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1
  }
}

const WhatAreTheBenefits = ({ t }) => {
  return (
    <section
      className="what-are-benefits"
      id="what-are-benefits"
    >
      <Container>
        <h2>{t("BENEFITS_TITLE")}<br />{t('BENEFITS_TITLE_SECOND_LINE')}</h2>
        <div className='info-tiles normal-tiles'>
        <Row>
          <Col md="6">
            <div className="cardbox">
              <img src={pic1} alt=""/>
              <p className="title">{t("BENEFITS_TITLE_1")}</p>
              <span>{t("BENEFITS_TITLE_SUB_1")}</span>
            </div>
          </Col>
          <Col md="6">
            <div className="cardbox">
              <img src={pic2} alt=""/>
              <p className="title">{t("BENEFITS_TITLE_2")}</p>
              <span>{t("BENEFITS_TITLE_SUB_2")}</span>
            </div>
          </Col>
          <Col md="6">
            <div className="cardbox">
              <img src={pic3} alt=""/>
              <p className="title">{t("BENEFITS_TITLE_3")}</p>
              <span>{t("BENEFITS_TITLE_SUB_3")}</span>
            </div>
          </Col>
          <Col md="6">
            <div className="cardbox">
              <img src={pic4} alt=""/>
              <p className="title">{t("BENEFITS_TITLE_4")}</p>
              <span>{t("BENEFITS_TITLE_SUB_4")}</span>
            </div>
          </Col>
        </Row>
        </div>
        

        {/* carousel for mobile */}
        
        <div className="info-tiles mobile-carousel">
          <Carousel
            className="carousel"
            responsive={responsive}
            slidesToSlide={1}
          > 
            <div className="cardbox">
              <img src={pic1} alt=""/>
              <p className="title">{t("BENEFITS_TITLE_1")}</p>
              <span>{t("BENEFITS_TITLE_SUB_1")}</span>
            </div>
            <div className="cardbox">
              <img src={pic2} alt=""/>
              <p className="title">{t("BENEFITS_TITLE_2")}</p>
              <span>{t("BENEFITS_TITLE_SUB_2")}</span>
            </div>
            <div className="cardbox">
              <img src={pic3} alt=""/>
              <p className="title">{t("BENEFITS_TITLE_3")}</p>
              <span>{t("BENEFITS_TITLE_SUB_3")}</span>
            </div>
            <div className="cardbox">
              <img src={pic4} alt=""/>
              <p className="title">{t("BENEFITS_TITLE_4")}</p>
              <span>{t("BENEFITS_TITLE_SUB_4")}</span>
            </div>
          
          </Carousel>
        </div>
        <div className='btn-container'>
          <Link to='/blog'>
            <Button className='btn-2 borderless' title={t("KNOW_MORE")}/>
          </Link>
        </div>
      </Container>
    </section>
  )
}

export default withTranslation()(WhatAreTheBenefits)
