import React from 'react'
import {
  Container,
} from 'reactstrap'
import './ClientReviews.scss'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { withTranslation } from  'react-i18next'

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  
const ClientReviews = ({t}) => {
  return (
    <section className='client-reviews' id='header'>
        <Container>
            <h2 className='section-title'>{t("CLIENT_REVIEWS_TITLE")}</h2>
            <Carousel 
              className='carousel' 
              responsive={responsive}
              showDots={true}>
                <div className='opinion'>
                   <div className='stars'>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <div className='review'>{t("REVIEW_TEXT_1")}</div>
                  <div className='author'>{t("REVIEW_AUTHOR_1")}</div>
                  <div className='author'>{t("REVIEW_GEN_1")}</div>
                </div>
                <div className='opinion'>
                  <div className='stars'>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <div className='review'>{t("REVIEW_TEXT_2")}</div>
                  <div className='author'>{t("REVIEW_AUTHOR_2")}</div>
                  <div className='author'>{t("REVIEW_GEN_2")}</div>
                </div>
                <div className='opinion'>
                   <div className='stars'>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <div className='review'>{t("REVIEW_TEXT_3")}</div>
                  <div className='author'>{t("REVIEW_AUTHOR_3")}</div>
                  <div className='author'>{t("REVIEW_GEN_3")}</div>
                </div> 
                <div className='opinion'>
                   <div className='stars'>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <div className='review'>{t("REVIEW_TEXT_4")}</div>
                  <div className='author'>{t("REVIEW_AUTHOR_4")}</div>
                  <div className='author'>{t("REVIEW_GEN_4")}</div>
                </div> 
                <div className='opinion'>
                   <div className='stars'>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <div className='review'>{t("REVIEW_TEXT_5")}</div>
                  <div className='author'>{t("REVIEW_AUTHOR_5")}</div>
                  <div className='author'>{t("REVIEW_GEN_5")}</div>
                </div> 
                <div className='opinion'>
                   <div className='stars'>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <div className='review'>{t("REVIEW_TEXT_6")}</div>
                  <div className='author'>{t("REVIEW_AUTHOR_6")}</div>
                  <div className='author'>{t("REVIEW_GEN_6")}</div>
                </div> 
                <div className='opinion'>
                   <div className='stars'>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <div className='review'>{t("REVIEW_TEXT_7")}</div>
                  <div className='author'>{t("REVIEW_AUTHOR_7")}</div>
                  <div className='author'>{t("REVIEW_GEN_7")}</div>
                </div> 
                <div className='opinion'>
                   <div className='stars'>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <div className='review'>{t("REVIEW_TEXT_8")}</div>
                  <div className='author'>{t("REVIEW_AUTHOR_8")}</div>
                  <div className='author'>{t("REVIEW_GEN_8")}</div>
                </div> 
                <div className='opinion'>
                   <div className='stars'>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <div className='review'>{t("REVIEW_TEXT_9")}</div>
                  <div className='author'>{t("REVIEW_AUTHOR_9")}</div>
                  <div className='author'>{t("REVIEW_GEN_9")}</div>
                </div> 
                <div className='opinion'>
                   <div className='stars'>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <div className='review'>{t("REVIEW_TEXT_10")}</div>
                  <div className='author'>{t("REVIEW_AUTHOR_10")}</div>
                  <div className='author'>{t("REVIEW_GEN_10")}</div>
                </div> 
                <div className='opinion'>
                   <div className='stars'>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <div className='review'>{t("REVIEW_TEXT_11")}</div>
                  <div className='author'>{t("REVIEW_AUTHOR_11")}</div>
                  <div className='author'>{t("REVIEW_GEN_11")}</div>
                </div> 
                <div className='opinion'>
                   <div className='stars'>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <div className='review'>{t("REVIEW_TEXT_12")}</div>
                  <div className='author'>{t("REVIEW_AUTHOR_12")}</div>
                  <div className='author'>{t("REVIEW_GEN_12")}</div>
                </div> 
              </Carousel>
        </Container>
    </section>
  )
}


export default withTranslation()(ClientReviews)
