import React from 'react'
import './BecomeCroatianCitizen.scss'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SalonaStep1 from '../../assets/Salona_Consulting_Cathedral_Zagreb.jpg'
import SalonaStep2 from '../../assets/approved.jpg'
import SalonaStep3 from '../../assets/salona-step-3.jpg'
import SalonaStep4 from '../../assets/people-step.jpg'
import SalonaStep5 from '../../assets/passport.jpg'
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const BecomeCroatianCitizen = ({ t, i18n }) => {

  return (
    <section className='become-croatian-citizen' id='header'>
      <Container>
        <h2>{t("BECOME_CROATIAN_CITIZEN_TITLE")}<br /> {t("BECOME_CROATIAN_CITIZEN_TITLE_SECOND")}</h2>
      </Container>
      <Carousel 
        className='carousel' 
        responsive={responsive}
        showDots={true}
        slidesToSlide={1}>
        <div className='opinion'>
          <div className='image-container'>
            <img src={SalonaStep1} className='image-carousel' alt="" />
            
          </div>
          <div className='description'>
              <h3 className='step'>{t("STEP1")}</h3>
              <p className='text'>
            {t("BECOME_CROATIAN_CITIZEN_1")}
          </p>  
          </div>
        </div>
        <div className='opinion'>
          <div className='image-container'>
            <img src={SalonaStep2} className='image-carousel' alt=""/>
          </div>
          <div className='description'>
          <h3 className='step'>{t("STEP2")}</h3>
          <p className='text'>
            {t("BECOME_CROATIAN_CITIZEN_2")}
          </p>
          </div>
          
        </div>
        <div className='opinion'>
          <div className='image-container'>
            <img src={SalonaStep3} className='image-carousel'alt="" />
            
          </div>
          <div className='description'>
          <h3 className='step'>{t("STEP3")}</h3>
          <p className='text'>
            {t("BECOME_CROATIAN_CITIZEN_3")}
          </p>
          </div>
        </div>
        <div className='opinion'>
          <div className='image-container'>
            <img src={SalonaStep4} className='image-carousel' alt=""/>
            
          </div>
          <div className='description'>
          <h3 className='step'>{t("STEP4")}</h3>
          <p className='text'>
            {t("BECOME_CROATIAN_CITIZEN_4")}
          </p>
          </div>
        </div>
        <div className='opinion'>
          <div className='image-container'>
            <img src={SalonaStep5} className='image-carousel' alt=""/>
            
          </div>
          <div className='description'>
          <h3 className='step'>{t("STEP5")}</h3>
          <p className='text'>
            {t("BECOME_CROATIAN_CITIZEN_5")}
          </p>
            
          </div>
        </div>
      </Carousel>
    </section>
  )
}


export default withTranslation()(BecomeCroatianCitizen)
