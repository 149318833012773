import React from 'react'
import {
  Container,
  Col,
  Row,
} from 'reactstrap'
import logo from '../../assets/logo_gray.png'
import './CroatianCitizenshipInfo.scss'
import sectionImageFlag from '../../assets/flag.png'
import { withTranslation } from 'react-i18next';

const CroatianCitizenshipInfo = ({ t, i18n }) => {
  return (
    <section className='croatian-citizenship-info' id='croatian-citizenship-info'>
       <img className='logo-bg-2' src={logo} alt='logo-bg' />
      <Container>
        <Row>
            <Col 
              lg={{ size: 5, order: 1}}
              xs={{ size: 12, order: 2}} sm={{ size: 12, order: 2}}   md={{ size: 4, order: 1}}  >
              <img className='section-img' src={sectionImageFlag} alt=""/>
            </Col>
            <Col 
            lg={{ size: 7, order: 2}} xs={{ size: 12, order: 1}} sm={{ size: 12, order: 1}}   md={{ size: 8, order:2  }}    
              className='section-description'>
                <h2>
                  {t("CROATIAN_CITIZENSHIP_HEADER")}
                </h2>
                <hr/>
                <p className='subtitle'>
                  {t("CROATIAN_CITIZENSHIP_SUBTITLE")}
                </p>

                <div className='info-boxes'>
                  <Row className='justify-content-md-center'>
                      <Col className='element' sm='10' md='4'>
                        <p>{t("CROATIAN_CITIZENSHIP_OPT1")}</p>
                      </Col>
                      <Col className='element' sm='10' md='4'>
                        <p>{t("CROATIAN_CITIZENSHIP_OPT2")}</p>
                      </Col>
                  </Row>
                </div>
            </Col>
        </Row>
      </Container>
    </section>
  )
}


export default withTranslation()(CroatianCitizenshipInfo)
